<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%;")
      div(style="width: 100%; display: flex; align-items: center;")
        Card_1_SVG_1
      div.d-flex.flex-column.justify-center(style="width: 100%")
        div
          p(style="color: #F93549; font-weight: 600; margin-bottom: 2em;") QUÉ ES
          p(style="font-size: 14px;") EL IPS es un indicador que nos permite comparar el gasto medio de los clientes en nuestro restaurante con el de la competencia, a partir del numero medio de ítems de cada familia que los clientes consumen.
          p(style="font-size: 14px;") Este indicador también nos permite entender como se construye el  ticket medio de nuestro restaurante en un  momento de consumo determinado.
          p(style="font-size: 14px;") Es un indicador muy importante dado que para los clientes  el precio de un restaurante  no son los precios unitarios de cada producto de la oferta  si no el gasto medio que realizan.


  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%;")
      div(style="width: 100%; display: flex; align-items: center;")
        Card_1_SVG_1_en
      div.d-flex.flex-column.justify-center(style="width: 100%")
        div
          p(style="color: #F93549; font-weight: 600; margin-bottom: 2em;") WHAT DOES IT MEAN
          p(style="font-size: 14px;") The IPS is an indicator that allows us to compare the average cost of customers in our restaurant with that of the competition, based on the average number of items of each Family that customers consume.
          p(style="font-size: 14px;") This indicator also allows us to understand the construction of the average ticket of our restaurant at a given time of consumption.
          p(style="font-size: 14px;") It's a significant indicator because, for the customers, the restaurant's Price is not the unit prices of each product in the offer but the average expenditure they make.

</template>

<script>
import Card_1_SVG_1 from "./assets/Card_1_SVG_1";
import Card_1_SVG_1_en from "./assets/Card_1_SVG_1_en";

export default {
  name: "Card_1",
  components: {Card_1_SVG_1, Card_1_SVG_1_en}
}
</script>

<style scoped>

</style>
